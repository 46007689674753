/* SCROLL BAR STYLING */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #010101; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #010101; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #010101; 
}


* {
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
}

body {
  margin: 0;
  padding: 0;
  font-family:'Nunito', sans-serif;
  font-size: 16px;
  overflow-y:hidden;
  font-weight:100;
  color: #5f5f5f;
  background: linear-gradient(180deg, white, #c1c1c138, white);
}

input {
	border:none;
	outline: none;
	font-size:50px;
  margin: 15px 0;
	border-radius: 15px;
	box-shadow: 1px 1px 5px 0px #00000029;
}

h2, p {
	font-weight: 100;
}

p {
	font-size:2rem;
}

.app {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width:100%;
	height: -webkit-fill-available;
	text-align: center;
	background: linear-gradient(180deg, #242222, black, #242222);
}

.space {
	perspective: 50px;
	-webkit-perspective-origin-y: -16px;
}

.button {
    padding: 10px 25px;
    border-radius: 30px;
}

.friendName {
	
}

.friendStat {
	position:absolute;
	animation: float ease-in-out 8s alternate infinite; 
	top: 52%;
}

@keyframes float {
	0%{top:5%;}
	55%{top:90%}
	100%{top:3%}
}

.friendMessages {
	display:flex;
}

.friendZone {
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100vh;
	z-index:1;
	perspective: 1000px;
}

.friendZone .memory {
	display: none;
}

.friendMemories{
	position: absolute;
	left:100%;
	width:100%;
	height:100%;
	background: linear-gradient(90deg, #6ebd7436, #46de7336, #6ebd7436);
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    transform: rotateX(45deg);
    perspective: 750px;
}

.friendMemories .friendStat > p, .friendMemories .friendStat > div, .friendMemories .friendStat > span {
	transform: rotateY(45deg);
	color:white;
}

.friendMemoriesTreasures{
	position: absolute;
	left:200%;
	width:100%;
	height:100%;
	background:linear-gradient(90deg, #afafaf, #afafaf36);
	display: flex;
	align-items: center;
    justify-content: center;
}

.friendMemories .friendStat {
	position: initial;
	margin: 25px;
	max-width: 300px;
	transform: rotateX(-27deg);
	filter: drop-shadow(2px 2px 4px #5f5f5f3b);
}

.friendMemoriesTreasure .friendStat {
	position: initial;
	margin: 25px;
	max-width: 300px;
}

#friendMemoriesChoose {
	left:0;
}

.hello {
	display:flex;
	flex-direction:column;
}

.logoBanner {
	display: flex;
  width: 100%;
  height: 5px;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 269px;
}

.logoDotContainer {
	width: 100%;
	max-width: 300px;
    height: 100px;
    position: fixed;
    top: 166px;
    opacity: 0;
    animation: logoHover 2s infinite;
}

.logoDotContainerTop {
	opacity: 0;
	width: 100%;
	max-width: 300px;
    height: 100px;
    position: fixed;
    top: 166px;
    animation: logoHoverTop 2s infinite;
}

.logoDot {
	position: absolute;
    width: 4px;
    height: 4px;
    background: #a4eced;
    z-index: 1000;
    border-radius: 100%;
}

.logoDotLowerLeft {
	bottom: 115px;
    left: 60px;
    box-shadow: 10px 2px 0px #a4eced;
}

.logoDotLowerLeft:nth-of-type(2) {
	bottom: 135px;
    left: 88px;
    box-shadow: -20px 4px 0px #a4eced;
}

.logoDotTopLeft {
	bottom: 206px;
    left: 85px;
    box-shadow: -7px -8px 0px #a4eced;
}

.logoDotLowerRight {
	bottom: 110px;
    right: 53px;
    box-shadow: 6px 8px 0px #a4eced;
}

.logoDotTopRight {
	bottom: 190px;
    right: 26px;
    box-shadow: -1px -12px 0px #a4eced;
}

@keyframes logoHover {
	0% {margin-top:0px;opacity:0;}
	15% {opacity:1;}
	70% {opacity:1;}
	100% {margin-top:10px; opacity: 0}
}

@keyframes logoHoverTop {
	0% {margin-top:0px;opacity:0;}
	15% {opacity:1;}
	70% {opacity:1;}
	100% {margin-top:-10px; opacity: 0}
}



#player {
	position: absolute;
    top: 0;
    left: 0;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    opacity: 0;
    pointer-events:none;
    z-index:2;
}

@keyframes focusIn {
	0% {filter:blur(100px);}
	100%{filter:blur(0px);}
}

@keyframes focusOut {
	0% {filter:blur(0px);}
	100%{filter:blur(100px);}
}

.focusOut {
	animation: focusOut 1s linear 0s 1 forwards!important;
}

#portal {
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position:relative;
	overflow:hidden;
	animation: focusIn 1s linear 0s 1 forwards;
	filter: blur(0px);
	transition: all 1s;
}

#portal > h2 {
	cursor:pointer;
	margin: 3px 0;
	font-size:1.5em;
	letter-spacing:2px;
}

#headerLogo {
	width: 100%;
	max-width:20rem;
	position: fixed;
	top: 18%;
	transform: scale(1.5);
	animation: focusIn 1s linear 0s 1 forwards;
}

#portal > h2, img {
	z-index: 1;
}

#portal > div > h2 {
	cursor: pointer;
}

#portal > video {
	height: 100%;
	width: 100%;
    position: fixed;
    right:0;
    top:0;
}

#progressBarMessage {
	position: absolute;
    font-size: 4rem;
    margin: 0;
    border-radius: 100%;
    transition: all 0.5s;
    transform: scale(.15);
    filter: drop-shadow(3px 4px 25px black);
}




/* ANIMATION CSS */

.ask {
	animation: askSpin infinite 5s alternate;
}

@keyframes askSpin {
  from { transform: rotateY(0deg); }
  to { transform: rotateY(540deg); }
}

.fadeIn {
	animation: fadeIn 1 5s forwards;
}

.fadeIntroSpan {
	animation: fadeIn 2s 1s infinite normal;
	margin-left:15px;
	opacity:0;
}

.friendIcon {
	height: 100px;
    width: 100px;
    background: cyan;
    border-radius: 100%;
    position: relative;
}

.friendIcon:before {
	content: "";
    position: absolute;
    top: 1%;
    left: 5%;
    width: 90%;
    height: 90%;
    border-radius: 100%;
    background: radial-gradient(circle at top, white, rgba(255, 255, 255, 0) 58%);
    -webkit-filter: blur(5px);
    filter: blur(5px);
    z-index: 2;
    box-shadow: 1px 1px 20px 20px white;
}

.happy .friendIcon {
    background: #eced6d;
}

.angry .friendIcon {
    background: #fa7c7c;
}

.sad .friendIcon {
    background: cyan;
}

@keyframes fadeIn {
	0% {opacity:0}
	100% { opacity:01}
}

@keyframes flipIntro {
	0% {transform: rotateY(90deg)}
	100% {transform: rotateY(0deg)}
}

.growShrink {
	animation: growShrink 1.5s linear 0s infinite alternate;
}

@keyframes growShrink {
	0% {transform: scale(1);}
	40% {transform: scale(1.45);}
	100% {transform: scale(1);}
}


/*  HUE CHANGE  */

.hueGlow {
  animation: hueGlow ease-in-out 10s infinite alternate;
}

@keyframes hueGlow {
  0%{background-color:#e6e6e6}
  25%{background-color:#ceffff}
  50%{background-color:#e6e6e6}
  75%{background-color:#fff8c3}
  50%{background-color:#e6e6e6}
}


/* WIGGLE ANIMATIONS */


.wordWiggle {
	animation: wordWiggle 5s linear 0s infinite alternate;
}

@keyframes wordWiggle {
	
	0%{transform:rotate3d(1, -5, 1, 15deg)}
	100%{transform:rotate3d(-1, 5, -1, 15deg)}
	
}

.radiusRumble {
    animation: radiusRumble 1s ease 3s infinite alternate;
}

@keyframes radiusRumble {
    from {border-radius: 100%;}
    to {border-radius: 45%;}
}


.introScreenContainer {
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity:1;
  animation: flipIntro 1s 0s forwards;
}

.introScreenContainer > h2 {
	z-index:999;
	font-family: 'Playfair Display', serif;
	font-weight:100;
}

.videoSongPage > p {
	font-size:1rem;
	margin:0 5px 5px 5px;
}




/*  CODE FOR 3D BOX   */

.boxScene {
  width: 300px;
  height: 200px;
  border: 1px solid #CCC;
  perspective: 400px;
  border:none;
  transition: all 1.5s
}

.box {
  width: 300px;
  height: 200px;
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(-50px);
  transition: transform 1s;
}

.box.show-front  { transform: translateZ( -50px) rotateY(   0deg); }
.box.show-back   { transform: translateZ( -50px) rotateY(-180deg); }
.box.show-right  { transform: translateZ(-150px) rotateY( -90deg); }
.box.show-left   { transform: translateZ(-150px) rotateY(  90deg); }
.box.show-top    { transform: translateZ(-100px) rotateX( -90deg); }
.box.show-bottom { transform: translateZ(-100px) rotateX(  90deg); }


.box__face {
  position: absolute;
  border: 2px solid white;
  font-size: 18px;
  font-weight: bold;
  color: white;
  text-align: center;
  overflow:hidden;
  display:flex;
  flex-direction:column;
  justify-content:center;
}

.box__face--front,
.box__face--back {
  width: 300px;
  height: 200px;
}

.box__face--right,
.box__face--left {
  width: 100px;
  height: 200px;
  left: 100px;
}

.box__face--top,
.box__face--bottom {
  width: 300px;
  height: 100px;
  top: 50px;
}

.box__face--front  { background: hsla(0, 0%, 90%, 0.43); }
.box__face--right  { background: hsla(191, 100%, 86%, 0.7); }
.box__face--back   { background: hsla(191, 100%, 86%, 0.7); }
.box__face--left   { background: hsla(191, 100%, 86%, 0.7); }
.box__face--top    { background: hsla(191, 100%, 86%, 0.7); }
.box__face--bottom { background: hsla(191, 100%, 86%, 0.7); }

.box__face--front  { transform: rotateY(  0deg) translateZ( 50px); }
.box__face--back   { transform: rotateY(180deg) translateZ( 50px); }

.box__face--right  { transform: rotateY( 90deg) translateZ(150px); }
.box__face--left   { transform: rotateY(-90deg) translateZ(150px); }

.box__face--top    { transform: rotateX( 90deg) translateZ(100px); }
.box__face--bottom { transform: rotateX(-90deg) translateZ(100px); }


/*  BOX ANIMATIONS  */

.boxWiggle {
	animation: boxWiggle 10s ease-in 1.5s infinite alternate;
}

@keyframes boxWiggle {
	0% {transform: translateZ( -50px) rotateX( 0deg) rotateY( 0deg);}
	20% {transform: translateZ( -50px) rotateX( -3deg) rotateY( -8deg);}
	40% {transform: translateZ( -50px) rotateX( 1deg) rotateY( 3deg);}
	60% {transform: translateZ( -50px) rotateX( 8deg) rotateY( 3deg);}
	80% {transform: translateZ( -50px) rotateX( -1deg) rotateY( -4deg);}
	100% {transform: translateZ( -50px) rotateX( 6deg) rotateY( -1deg);}
}


/*  BOX SITE CODE INTEGRATION  */

.box .button {
	border-radius: 0;
    margin: 0;
    width: 24%;
    margin-left: 25px;
    padding-left: 0;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.box #videoBgPic {
 	position:absolute;
}

.box__face--front {
	justify-content:flex-start;
	align-items:center;
	flex-direction: row;
}

.box__face--front  p {
 	position:absolute;
 	bottom: 2px;
    font-weight: bold;
    line-height: 27px;
    font-size: 32px;
    color: #4c4c4c;
    font-family: 'Amatic SC', 'Gaegu', cursive;
    padding-left: 23px;
}

.box__face--front  h2 {
 	color: #484848;
 	font-size: 36px;
    color: #545454;
    padding-top: 13px;
    font-weight: 200;
    border-bottom: 3px solid white;
}

.box__face--right  p, .box__face--left  p, .box__face--bottom  p, .box__face--top  p {
	font-size: 16px;
    line-height: 20px;
    color: black;
    padding: 0 5px;
}




/*  MEMORY BALL  */

.ball {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  position: relative;
  background: radial-gradient(circle at bottom, #81e8f6, #76deef 10%, #055194 80%, #062745 100%);
}

.ball:before {
    content: "";
    position: absolute;
    top: 1%;
    left: 5%;
    width: 90%;
    height: 90%;
    border-radius: 100%;
    background: radial-gradient(circle at top, white, rgba(255, 255, 255, 0) 58%);
    -webkit-filter: blur(5px);
    filter: blur(5px);
    z-index: 2;
    box-shadow: 1px 1px 20px 20px white;
}

.ball:after {
    content: "";
    position: absolute;
    display: none;
    top: 5%;
    left: 10%;
    width: 80%;
    height: 80%;
    border-radius: 100%;
    -webkit-filter: blur(1px);
    filter: blur(1px);
    z-index: 2;
    -webkit-transform: rotateZ(-30deg);
    transform: rotateZ(-30deg);
    box-shadow: 1px 1px 20px 20px white;
}

.ball .shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 50%);
    -webkit-transform: rotateX(90deg) translateZ(-160px);
    transform: rotateX(90deg) translateZ(-160px);
    z-index: 1; }
  .ball.plain {
    background: black; }
    .ball.plain:before, .ball.plain:after {
      display: none; 
 }

/*  CODE FOR BALL BUBBLE  */

.ball.bubble {
    background: radial-gradient(circle at 50% 55%, rgba(240, 245, 255, 0.9), rgba(240, 245, 255, 0.9) 40%, rgba(225, 238, 255, 0.8) 60%, rgba(43, 130, 255, 0.4));
    -webkit-animation: bubble-anim 2s ease-out infinite;
    animation: bubble-anim 2s ease-out infinite; 
    box-shadow: 1px 1px 20px 20px white;
}

.ball.bubble:before {
      -webkit-filter: blur(0);
      filter: blur(0);
      height: 80%;
      width: 40%;
      background: radial-gradient(circle at 130% 130%, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.8) 58%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
      -webkit-transform: translateX(131%) translateY(58%) rotateZ(168deg) rotateX(10deg);
      transform: translateX(131%) translateY(58%) rotateZ(168deg) rotateX(10deg); 
}

.ball.bubble:after {
      display: block;
      background: radial-gradient(circle at 50% 80%, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 74%, white 80%, white 84%, rgba(255, 255, 255, 0) 100%); 
}

.stage {
  width: 50px;
  height: 50px;
  display: inline-block;
  margin: 20px;
  margin-top: 50px;
  margin-left: -75px;
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -ms-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
  -webkit-perspective-origin: 50% 50%;
  -moz-perspective-origin: 50% 50%;
  -ms-perspective-origin: 50% 50%;
  -o-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
}

@-webkit-keyframes bubble-anim {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }

  20% {
    -webkit-transform: scaleY(0.95) scaleX(1.05);
    transform: scaleY(0.95) scaleX(1.05); }

  48% {
    -webkit-transform: scaleY(1.1) scaleX(0.9);
    transform: scaleY(1.1) scaleX(0.9); }

  68% {
    -webkit-transform: scaleY(0.98) scaleX(1.02);
    transform: scaleY(0.98) scaleX(1.02); }

  80% {
    -webkit-transform: scaleY(1.02) scaleX(0.98);
    transform: scaleY(1.02) scaleX(0.98); }

  97%, 100% {
    -webkit-transform: scale(1);
    transform: scale(1); } 
}

@keyframes bubble-anim {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }

  20% {
    -webkit-transform: scaleY(0.95) scaleX(1.05);
    transform: scaleY(0.95) scaleX(1.05); }

  48% {
    -webkit-transform: scaleY(1.1) scaleX(0.9);
    transform: scaleY(1.1) scaleX(0.9); }

  68% {
    -webkit-transform: scaleY(0.98) scaleX(1.02);
    transform: scaleY(0.98) scaleX(1.02); }

  80% {
    -webkit-transform: scaleY(1.02) scaleX(0.98);
    transform: scaleY(1.02) scaleX(0.98); }

  97%, 100% {
    -webkit-transform: scale(1);
    transform: scale(1); } 
}









.textbox {
  left:0%;
  width: 500px;
  height: 110px;
  position: relative;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-animation: turnaround 20s infinite;
          animation: turnaround 20s infinite;
}
.textbox-box {
  background-color: #aefeff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0 0 3px #ffffff;
  position: relative;
  -webkit-animation: hover 2s alternate infinite;
          animation: hover 2s alternate infinite;
  height: 100%;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition: all 0.2s ease;
}
.textbox-field {
  display: flex;
  flex-direction: column;
  padding: 10px 15px 15px;
  align-self: stretch;
  border-radius: 5px;
}
.textbox-label {
  -webkit-transform: translateZ(20px);
          transform: translateZ(20px);
  text-transform: uppercase;
  font-weight: bold;
  -webkit-animation: hover 2s -0.2s alternate infinite;
          animation: hover 2s -0.2s alternate infinite;
  color: #272343;
  -webkit-filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.5));
}
.textbox-action {
  height: 80px;
  width: 80px;
  min-width: 80px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  -border-radius: 100px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-animation: hover 2s -0.4s alternate infinite;
          animation: hover 2s -0.4s alternate infinite;
  background-color: #ffffff;
  position: relative;
  transition: all 0.2s ease;
}
.textbox-action svg {
  fill: #272343;
  width: 65%;
  height: 65%;
  -webkit-animation: hover 2s -0.6s alternate infinite;
          animation: hover 2s -0.6s alternate infinite;
  -webkit-filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.5));
}
.textbox-action .textbox-face {
  background-color: #66a8a8;
  transition: all 0.2s ease;
}
.textbox-action .textbox-side {
  width: 10px;
}
.textbox-action .textbox-bottom,
.textbox-action .textbox-top {
  height: 10px;
}
.textbox-action:hover {
  cursor: pointer;
  background-color: #9ccece;
}
.textbox-action:hover .textbox-face {
  background-color: #468282;
}
.textbox-text {
  width: 90%;
  height: 100%;
  padding:5px 15px;
  border: none;
  background-color: #000000a6;
  /*font: 2.5rem sans-serif;*/
  animation: hover 2s -0.4s alternate infinite;
  display: block;
  color: #ffffff;
  -webkit-filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.5));
}
.textbox-text::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 0.5;
}
.textbox-text:-ms-input-placeholder {
  color: #ffffff;
  opacity: 0.5;
}
.textbox-text::-ms-input-placeholder {
  color: #ffffff;
  opacity: 0.5;
}
.textbox-text::placeholder {
  color: #ffffff;
  opacity: 0.5;
}
.textbox-text:focus {
  outline: none;
}
.textbox-face {
  position: absolute;
  background-color: #e6e6e6;
  left: 0;
}
.textbox-side {
  -webkit-transform: rotateY(90deg);
          transform: rotateY(90deg);
  height: 100%;
  width: 20px;
  top: 0;
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
}
.textbox-bottom {
  -webkit-transform: rotateX(90deg);
          transform: rotateX(90deg);
  height: 20px;
  width: 100%;
  bottom: 0;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
}
.textbox-top {
  transform: rotateX(-90deg);
  height: 20px;
  width: 100%;
  top: 0;
          transform-origin: 50% 0;
}

@keyframes hover {
  from {
            transform: translateZ(10px);
  }
  to {
            transform: translateZ(20px);
  }
}

@keyframes turnaround {
  0% {
    transform: perspective(1000px) rotateY(10deg) rotateZ(-5deg) rotateX(10deg);
  }
  33% {
    transform: perspective(1000px) rotateY(5deg) rotateZ(-5deg) rotateX(-10deg);
  }
  67% {
    transform: perspective(1000px) rotateY(0deg) rotateZ(0deg) rotateX(0deg);
  }
  100% {
    transform: perspective(1000px) rotateY(10deg) rotateZ(-5deg) rotateX(10deg);
  }
}

@keyframes turnaroundSmall {
	  0% {
	    transform: perspective(1000px) rotateY(10deg) rotateZ(-5deg) rotateX(10deg) scale(.8);
	  }
	  33% {
	    transform: perspective(1000px) rotateY(5deg) rotateZ(-5deg) rotateX(-10deg) scale(.8);
	  }
	  67% {
	    transform: perspective(1000px) rotateY(0deg) rotateZ(0deg) rotateX(0deg) scale(.8);
	  }
	  100% {
	    transform: perspective(1000px) rotateY(10deg) rotateZ(-5deg) rotateX(10deg) scale(.8);
	  }
	}


.reference {
  position: absolute;
  right: 20px;
  bottom: 15px;
}
.reference img {
  width: 35px;
  height: 35px;
}






/* NEW CODE */

.frame {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
}

.laurel0 .base {
  width: 85px;
}

.laurel0 {
  transform: scale(1.11);
  margin-top: 8px;
  margin-left: 20px;
}

.laurel1 {
  transform: scale(0.58);
}

.laurel1 .wings {
  margin-right: 0!important;
  width: 50px!important;
}

.laurel1 .base {
  width: 108px;
  margin-left: 8px;
}

.laurels {
	position: absolute;
	bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.laurels > div {
	display: flex;
  justify-content: center;
  align-items: center;
}

.laurels .wings {
  width: 39px;
  margin-top: -3px;
}

.laurels .wings:first-of-type {
  margin-right: -21px;
  animation: verticalSpinL linear 5s alternate infinite;
}

.laurels .wings:last-of-type {
  margin-left: -21px;
  animation: verticalSpinR linear 5s alternate infinite;
}

.menuDivider {
  width: 100%;
  border-bottom: 1px solid #5f5f5f;
}

.videoMenu:after {
  content: url("Zero-Heart-Big.svg");
  position: absolute;
  left: -14px;
  margin-top: 36px;
  width: 30px;
  /* SPHERE VALUE
  content: "";
  position: absolute;
  left: 7px;
  margin-top: 43px;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  box-shadow:inset 0px 0px 0px 3px #6d6d6d;
  */
}

.videoMenu:before {
  content: url("Zero-Heart-Big.svg");
  position: absolute;
  right: -14px;
  margin-top: 36px;
  width: 30px;
  /* SPHERE VALUE
  content: "";
  position: absolute;
  right: 7px;
  margin-top: 43px;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  box-shadow: inset 0px 0px 0px 3px #6d6d6d;
  */
}

.videoMenu {
	position: relative;
  display: flex;
	flex-direction: column;
	padding: 20px;
	margin-top: 250px;
	text-align: left;
	z-index: 1;
}

.videoMenu > h1 {
	margin: 5px;
	font-weight: 100;
  font-size: 25px;
  cursor: pointer;
  color: #c61010;;
}

.videoMenu > h1:hover {
	animation: none;
  color: #fff;
  opacity: 1;
  padding: 5px 10px;
}

.videoMenu > h1:last-of-type {
  border:none;
}

.videoPage {
	justify-content: flex-end!important;
}


/* #s */

#film {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: -webkit-fill-available
}

#filmFrame {
	opacity: 0;
  background: black;
}


.blink {
	animation: blink ease-in-out 2s alternate infinite;
}

.blink0 {
  opacity: 0;
  animation: blinkMenu ease-in-out 3s infinite
}

.blink1 {
  opacity: 0;
  animation: blinkMenu 4s ease-in-out 4s infinite;
}


@keyframes blink {
	0%{opacity: 1}
	55%{opacity:0}
	100%{opacity: 1}
}

@keyframes blinkMenu {
  0%{opacity: 0}
  25%{opacity:1}
  75%{opacity:0}
  100%{opacity: 0}
}

.verticalSpinL {
  animation: verticalSpinL 2s linear 5s alternate infinite;
}

.verticalSpinR {
  animation: verticalSpinR 2s linear 5s alternate infinite;
}

@keyframes verticalSpinL {
  0%{transform: rotateY(0deg)}
  100%{transform: rotateY(360deg)}
}

@keyframes verticalSpinR {
  0%{transform: rotateY(360deg)}
  100%{transform: rotateY(0deg)}
}

#checkboxForm {
  display: flex;
  flex-direction: column;
  width: 300px;
  position: fixed;
  bottom: 10%;
  animation: fadeIn 1 1.5s forwards;
}

#checkboxForm label {
  text-align: left;
  margin-left: 15px;
}

#checkboxForm > div {
  display: flex;
  align-items: center;
}

.submitButton {
  flex-direction: column;
  padding: 20px;
  text-align: left;
  margin: 0;
  left: 52%;
  bottom: 17%;
  position: absolute;
  transform: rotate(-90deg);
  animation: fadeIn 1 1.5s forwards;
}

.yesNo {
  bottom: 20%;
  position: absolute!important;
  text-align: center;
  animation: fadeIn 1 1.5s forwards;
}

input[type="checkbox" i] {
  background: black;
}

.dataLoadingHeader {
  perspective: 1000px
}

.dataLoadingHeader img {
  animation: dataLogoSpin 2 5s forwards!important;
}

.loveMatchData {
  position: absolute;
  top: 0;
  margin-left: -19%;
  width: 600px;
  transform: rotate(90deg);
  animation: dataFall 2 5s forwards;
}

.loveMatchData:nth-of-type(1) {
  left: -2%;
  transform: translateZ(-200px) rotate(90deg);
}

.loveMatchData:nth-of-type(2) {
  left: 15%;
  margin-top: -136px;
}

.loveMatchData:nth-of-type(3) {
  left: 20%;
  margin-top: -106px;
  transform: translateZ(-200px) rotate(90deg)
}

.loveMatchData:nth-of-type(4) {
  left: 36%;
  transform: translateZ(-495px) rotate(90deg);
  margin-top: 50px;
}

.loveMatchData:nth-of-type(5) {
  left: 52%;
  transform: translateZ(-100px) rotate(90deg);
  margin-top: -256px;
}

.loveMatchData:nth-of-type(6) {
  left: 68%;
  transform: translateZ(-400px) rotate(90deg);

}

.loveMatchData:nth-of-type(7) {
  left: 75%;
  margin-top: -129px;
  transform: translateZ(-180px) rotate(90deg)
}

.loveMatchData:nth-of-type(8) {
  left: 85%;
  transform: translateZ(-60px) rotate(90deg);
}

@keyframes dataFall {
  0%{top:-100%;}
  100%{top:195%;}
}

@keyframes dataLogoSpin {
  0%{transform: rotate3d(1, 1, 1, 0deg)}
  100%{transform: rotate3d(1, 1, 1, 1080deg);}
}

.perspective {
  perspective: 1000px;
}

#searchCompleteMessage { 
  opacity:0;
  position: absolute;
  bottom: 16%;
}

#calculatingMessage { 
  bottom: 16%;
  position: absolute;
}


.butterflyCard {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #ffffffe8!important;
    color: black;
}

.butterflyCard p {
  font-size: 25px;
  margin: 10px 15px;
  padding: 0;
  line-height: 30px;
  background: #e8e8e8e0;
}

.butterflyCard div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84%;
    margin: 0px 15px;
    overflow: hidden;
}

.butterflyCard div > img {
  background: #e8e8e8e0;
  height: 125px;
}

.butterflyCard .description {
  padding: 5px 15px;
  font-size: 16px;
  line-height: 22px;
}

.carousel__cell {
  position: absolute;
  width: 250px;
  height: 280px;
  border: 2px solid #636363;
  line-height: 116px;
  font-size: 80px;
  font-weight: bold;
  text-align: center;
  transition: all 1s;
}

.introItem {
  margin-top: 500%;
}

.introItemPerspective {
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .meetingDescription {
    width: 60%;
    font-size: 18px;
    align-self: flex-end;
    margin-bottom: 100px;
    opacity: 0;
  }


@media all and (max-width: 1024px) and (min-width: 768px) { 
  
  .meetingDescription {
    margin-bottom: 150px;
    font-size: 18px;
  }

  .submitButton {
    bottom:14%;
  }
}

@media all and (min-width: 1800px) { 
  .meetingDescription {
    font-size: 22px;
    margin-bottom: 200px;
  }

  .submitButton {
    bottom:14%;
  }
}

@media all and (max-width: 666px) {

  .app {
    overflow-y:hidden;
  }

  .app p, .app h1, .app h2, .app h3, .app input {
        font-size: 1.8rem;
        max-width:300px;
  }

  .laurels {
    justify-content: center;
  }

  .laurel0 {
    margin-left: 0px;
    margin-right: 20px;
  }

  .meetingDescription {
    font-size: 16px!important;
    margin: auto auto 25px auto;
    width: 97%;
    line-height: 22px;
  }

  .textbox-field {
    width: 100%;
  }

  .videoIndexControl {
    bottom: 25px;
      top: auto;
  }

  .videoText0, .videoText1 {
    width:80%;
  }

  #portal {
    justify-content:center;
  }

  #portal > video {
    width:initial;
    right: -250%;
  }

  #checkboxForm {
    margin-left: 20%;
  }

  .introItem {
  transform: scale(1.2)!important;
  }

  .loveMatchData {
    margin-left: -34%;
  }

  #headerLogo {
    transform: scale(1);
  }

}

@media all and (max-width: 350px) {

  .butterflyCard p {
    font-size: 16px;
  }

  .butterflyCard .description {
    padding: 5px 15px;
    font-size: 12px;
    line-height: 15px;
  }

  .meetingDescription {
    font-size: 13px!important;
  }

  .introItem {
    transform: scale(1)!important;
  }

}





.numberSheet {
  display: flex;
  border: 1px solid black;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.numberSheet > div:nth-of-type(even){
  color: green;
}

.numberSheet > div:nth-of-type(odd){
  color: red;
}

.number {
  padding: 5px 10px;
  border: 1px solid black;
  width: 60px;
}



.barsSheet {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.bar {
  width: 100px;
  height: 156px;
  background: #58f8f8;
  margin: 0 70px;
}

.backBar {
  position: absolute;
  border-bottom: 1px solid #c1c1c1;
  width: 59%;
  display: flex;
  justify-content: space-between;
}

.rotate90{
  transform: rotate(90deg);
  left: -9%;
}